import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'octali-lib-firebase-utils';
import { ProductDatasResolver, ProductResolver } from 'octali-lib-shop';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'accueil',
    pathMatch: 'full'
  },
  {
    path: 'accueil',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'connexion',
    loadChildren: () => import('./pages/user/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'inscription',
    loadChildren: () => import('./pages/user/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'categories',
    loadChildren: () => import('./pages/category/category.module').then( m => m.CategoryPageModule)
  },
  {
    path: 'categories/:id',
    loadChildren: () => import('./pages/category/category.module').then( m => m.CategoryPageModule)
  },
  {
    path: 'categories/:name/:id',
    loadChildren: () => import('./pages/category/category.module').then( m => m.CategoryPageModule)
  },
  {
    path: 'produits/:id',
    loadChildren: () => import('./pages/product/product-detail/product-detail.module').then( m => m.ProductDetailPageModule),
    resolve: {
      datas: ProductResolver
    }
  },
  {
    path: 'produits/:name/:id',
    loadChildren: () => import('./pages/product/product-detail/product-detail.module').then( m => m.ProductDetailPageModule),
    resolve: {
      datas: ProductDatasResolver
    }
  },
  {
    path: 'produits/:manufacturer/:name/:id',
    loadChildren: () => import('./pages/product/product-detail/product-detail.module').then( m => m.ProductDetailPageModule),
    resolve: {
      datas: ProductDatasResolver
    }
  },
  {
    path: 'produits/:category/:manufacturer/:name/:id',
    loadChildren: () => import('./pages/product/product-detail/product-detail.module').then( m => m.ProductDetailPageModule),
    resolve: {
      datas: ProductDatasResolver
    }
  },
  {
    path: 'panier',
    loadChildren: () => import('./pages/cart/cart.module').then( m => m.CartPageModule)
  },
  {
    path: 'mon-adresse',
    loadChildren: () => import('./pages/user/address/address.module').then( m => m.AddressPageModule)
  },
  {
    path: 'mon-compte',
    loadChildren: () => import('./pages/user/account/account.module').then( m => m.AccountPageModule)
  },
  {
    path: 'paiement',
    loadChildren: () => import('./pages/paiement/paiement.module').then( m => m.PaiementPageModule)
  },
  {
    path: 'mes-commandes',
    loadChildren: () => import('./pages/user/orders/orders.module').then( m => m.OrdersPageModule)
  },
  {
    path: 'order-detail',
    loadChildren: () => import('./pages/user/order/order-detail/order-detail.module').then( m => m.OrderDetailPageModule)
  },
  {
    path: 'mot-de-passe-oublie',
    loadChildren: () => import('./pages/user/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'fournisseurs/:name/:id',
    loadChildren: () => import('./pages/manufacturer/manufacturer.module').then( m => m.ManufacturerPageModule)
  },
  {
    path: 'informations/conditions-generales-vente',
    loadChildren: () => import('./pages/legacy/cgu/cgu.module').then( m => m.CguPageModule)
  },
  {
    path: 'informations/politique-livraison',
    loadChildren: () => import('./pages/legacy/shipping/shipping.module').then( m => m.ShippingPageModule)
  },
  {
    path: 'informations/politique-confidentialite',
    loadChildren: () => import('./pages/legacy/privacy/privacy.module').then( m => m.PrivacyPageModule)
  },
  {
    path: 'informations/a-propos',
    loadChildren: () => import('./pages/legacy/about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'informations/cookies',
    loadChildren: () => import('./pages/legacy/cookies/cookies.module').then( m => m.CookiesPageModule)
  },
  {
    path: 'informations/mentions-legales',
    loadChildren: () => import('./pages/legacy/mention/mention.module').then( m => m.MentionPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
