import { Component } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Profile, UserService } from 'octali-lib-firebase-utils';
import { Category, CategoryService } from 'octali-lib-shop';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public profile: Profile|null;
  public categories: Array<Category> = [];
  public categoryId:string;
  public categoriesId:Map<string,Category>;

  private descending: boolean = true;
  public order: number = 1;
  public column: string = 'name';

  constructor(
    private categoryService:CategoryService,
    private profileService: UserService,
    private navCtrl: NavController,
  ) {
    combineLatest( [
      this.profileService.getUser(),
      this.categoryService.getCategoriesIdObs(),
      this.categoryService.getCategoriesObs()
    ])
    .subscribe( ([user, categoriesId, categories] ) => {
      if( user && categoriesId ){
        this.profile = user;
        this.categoriesId = categoriesId;
        if( categories.length > 0 ) {
          this.categories = categories.filter( cat => (cat.level === 0) );
        }
      }
    });
  }


  getLink(category) {
    let name = category.name.replace(/\/|\s/g, "-").toLowerCase();
    return "categories/"+encodeURI(name.toLowerCase())+"/"+category.id;
  }
  goTo(page){
    this.navCtrl.navigateRoot( "produit/"+page );
  }

  seeCategory(category) {
    let name = category.name.replace(/\/|\s/g, "-").toLowerCase();
    this.navCtrl.navigateRoot("categories/"+encodeURI(name.toLowerCase())+"/"+category.id);
  }
}
