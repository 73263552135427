import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';

import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { PipesModule } from 'octali-lib-utils';

const config = {
  apiKey: "AIzaSyB4iu5fmsyDO1E6YCtxeVlKYTtKk-tzkuk",
  authDomain: "degrifflaine-shop.firebaseapp.com",
  databaseURL: "https://degrifflaine-shop.firebaseio.com",
  projectId: "degrifflaine-shop",
  storageBucket: "degrifflaine-shop.appspot.com",
  messagingSenderId: "655736537426",
  appId: "1:655736537426:web:edc253291da27cf4"
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule,
    AngularFireModule.initializeApp(config),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFirestoreModule.enablePersistence(),
    ReactiveFormsModule,
    HttpClientModule,
    PipesModule
  ],  
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
     InAppBrowser],
  bootstrap: [AppComponent],
})
export class AppModule {}
